import { render, staticRenderFns } from "./changeLogAvatars.vue?vue&type=template&id=2c888068&scoped=true&"
import script from "./changeLogAvatars.vue?vue&type=script&lang=js&"
export * from "./changeLogAvatars.vue?vue&type=script&lang=js&"
import style0 from "./changeLogAvatars.vue?vue&type=style&index=0&id=2c888068&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c888068",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VTooltip})
