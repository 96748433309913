<template>
  <v-dialog
      v-model="dialog"
      max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn elevation="4"
             v-bind="attrs"
             v-on="on"
             icon
             color="red" small class="white--text">
        <v-icon small dark>
          mdi-trash-can-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 red--text darken-2">
        删除订单
      </v-card-title>
      <v-card-text>

        注意! 此操作<strong>无法回退</strong>, 确定要删除么?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="green darken-1"
            text
            @click="dialog = false"
        >
          我再想想
        </v-btn>
        <v-btn
            color="red"
            text
            @click="$emit('confirmDelete')"
        >
          删除
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "delete",
  data() {
    return {
      dialog: false
    }
  },
  methods: {
    confirm() {
      this.$emit('confirmDelete')
      this.dialog = false
    }
  }
}
</script>

<style scoped>

</style>