<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-icon style="vertical-align: middle"
              v-bind="attrs"
              :color="filtered?'primary':''"
              v-on="on"
      >
        {{filtered?'mdi-filter-settings':'mdi-filter-outline'}}
      </v-icon>
    </template>
    <v-card>
      <v-card-title class="text-h7 primary darken-1 white--text">
        工单过滤
      </v-card-title>
      <v-card-text class="pa-4">
        <div class="text-h6 mb-2">
          状态
        </div>

        <v-chip-group
            v-model="filter.status"
            column
            multiple
        >
          <v-chip v-for="item in statusList"
                  :key="item.code"
                  :color="item.color"
                  filter
                  text-color="white"
                  :value="item.code"
          >
            {{ item.name }}
          </v-chip>
        </v-chip-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="grey darken-1"
            text
            @click="clearFilter"
        >
          清除
        </v-btn>
        <v-btn
            color="primary darken-1"
            text
            @click="submitFilter"
        >
          过滤
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "carServiceOrderFilter",
  model: {
    prop: 'value',
    event: 'submit'
  },
  props: ['value'],
  data() {
    return {
      dialog: false,
      filter: {
        status: [],
      },
      filtered: false,
    }
  },
  methods: {
    submitFilter() {
      this.filtered = true
      this.dialog = false
      console.log(this.filter)
      this.$emit('submit', this.filter)

    },
    clearFilter() {
      this.filter = {}
      this.filtered = false
      this.$emit('submit', this.filter)
      this.dialog = false

    }
  },
  computed: {
    statusList() {
      const list = this.$store.state.projectSettings.find(s => {
        return s.name === 'JD轮胎险'
      })
      return list.status
    }
  }
}
</script>

<style scoped>

</style>