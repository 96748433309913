<template>
  <v-dialog v-model="dialog"
            fullscreen
            transition="dialog-bottom-transition"
  >
    <template #activator="{ on }">
      <v-btn elevation="4" v-bind="$attrs" v-on="on">
        <slot></slot>
      </v-btn>
    </template>
    <template #default>
      <validation-observer ref="observer" v-slot="{invalid}">
        <v-card>
          <v-overlay :value="overlay">
            <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
            ></v-progress-circular>
          </v-overlay>
          <v-toolbar color="primary" dark dense>
            <v-btn icon @click="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>{{ title }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-card class="mb-6" elevation="2">
                    <v-card-title>
                      工单信息
                    </v-card-title>
                    <v-card-text>
                      <validation-provider name="状态" rules="required" v-slot="{ errors }">
                        <v-select v-model="serviceOrder.status" required
                                  prepend-icon="mdi-progress-check"
                                  :readonly="readonly"
                                  outlined
                                  dense
                                  elevation="3"
                                  :items="status" label="状态"
                                  :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                      <validation-provider name="客户姓名" rules="required" v-slot="{ errors }">
                        <v-text-field label="客户姓名" v-model.trim="serviceOrder.customer.name"
                                      prepend-icon="mdi-account-star-outline"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="联系电话" rules="required||min:11|max:11" v-slot="{ errors }">
                        <v-text-field label="联系电话" v-model.trim="serviceOrder.customer.phone"
                                      :error-messages="errors"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      counter
                                      type="number"
                                      prepend-icon="mdi mdi-cellphone" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="地址" rules="required" v-slot="{ errors }">
                        <v-textarea label="地址" v-model="serviceOrder.customer.address"
                                    :readonly="readonly"
                                    outlined
                                    dense
                                    no-resize
                                    rows="2"
                                    prepend-icon="mdi-map-marker-outline"
                                    :error-messages="errors" required></v-textarea>
                      </validation-provider>
                      <validation-provider name="故障类型" rules="required" v-slot="{ errors }">
                        <v-select v-model="serviceOrder.trouble.category" required :items="tire.problem"
                                  label="故障类型"
                                  :readonly="readonly"
                                  prepend-icon="mdi-alert"
                                  outlined
                                  dense
                                  :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                      <validation-provider name="故障描述" rules="required" v-slot="{ errors }">
                        <v-textarea label="故障描述" v-model="serviceOrder.trouble.desc"
                                    :readonly="readonly"
                                    outlined
                                    dense
                                    no-resize
                                    rows="2"
                                    prepend-icon="mdi-alert"
                                    :error-messages="errors" required></v-textarea>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                  <v-card class=mb-6 elevation="2">
                    <v-card-title>
                      订单信息
                    </v-card-title>
                    <v-card-text>
                      <validation-provider name="主品订单号" rules="required||min:10|max:16" v-slot="{ errors }">
                        <v-text-field label="主品订单号" v-model="serviceOrder.mainOrder.code"
                                      prepend-icon="mdi mdi-file-outline"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      counter
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="商品数量" rules="required" v-slot="{ errors }">
                        <v-text-field label="商品数量" v-model="serviceOrder.mainOrder.qty"
                                      prepend-icon="mdi mdi-numeric"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="主品订单金额" rules="required" v-slot="{ errors }">
                        <v-text-field label="主品订单金额" v-model.number="serviceOrder.mainOrder.amount"
                                      prepend-icon="mdi mdi-currency-cny"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      type="number"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="服务订单号" rules="required||min:10|max:16" v-slot="{ errors }">
                        <v-text-field label="服务订单号" v-model="serviceOrder.secondaryOrder.code"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      counter
                                      prepend-icon="mdi-hammer-screwdriver" :error-messages="errors"
                                      required></v-text-field>
                      </validation-provider>
                      <validation-provider name="主品收货/安装日期" rules="required" v-slot="{ errors,validate }">
                        <datepickerField label="主品收货/安装日期" :error="errors" @blur="validate()"
                                         name="主品收货/安装日期"
                                         :readonly="readonly"
                                         outlined
                                         dense
                                         v-model="serviceOrder.mainOrder.finishedDate"></datepickerField>
                      </validation-provider>
                      <validation-provider name="轮胎品牌" rules="required" v-slot="{ errors }">
                        <v-autocomplete v-model="serviceOrder.case.brand" required :items="tire.brandSelectionItems"
                                        :readonly="readonly"
                                        outlined
                                        dense
                                        label="轮胎品牌"
                                        prepend-icon="mdi-map-marker-outline"
                                        :error-messages="errors"
                        ></v-autocomplete>
                      </validation-provider>
                      <validation-provider name="DOT" rules="required||min:4|max:4" v-slot="{ errors }">
                        <v-text-field label="DOT" v-model="serviceOrder.case.dot"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      counter
                                      prepend-icon="mdi mdi-numeric-4-box-multiple-outline"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="轮胎花纹" rules="required" v-slot="{ errors }">
                        <v-text-field label="轮胎花纹" v-model="serviceOrder.case.轮胎花纹"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      prepend-icon="mdi-tire"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="轮胎规格" rules="required" v-slot="{ errors }">
                        <v-text-field label="轮胎规格" v-model="serviceOrder.case.轮胎规格"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      prepend-icon="mdi mdi-perspective-more"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="车牌号" rules="required" v-slot="{ errors }">
                        <v-text-field label="车牌号" v-model="serviceOrder.case.车牌号"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      counter
                                      prepend-icon="mdi-car"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                  <v-card class="mb-6" elevation="2">
                    <v-card-title>赔付</v-card-title>
                    <v-card-text>
                      <validation-provider name="赔付方式" rules="required" v-slot="{ errors }">
                        <v-select v-model="serviceOrder.claimPayment.method" required
                                  prepend-icon="mdi-credit-card-outline"
                                  :readonly="readonly"
                                  outlined
                                  dense
                                  :items="['京东E卡','现金','换新采购']" label="赔付方式"
                                  :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                      <validation-provider name="赔付数量" rules="required" v-slot="{ errors }">
                        <v-text-field label="赔付数量" v-model.number="serviceOrder.claimPayment.qty"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      type="number"
                                      prepend-icon="mdi mdi-numeric"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="赔付金额" rules="required" v-slot="{ errors }">
                        <v-text-field label="赔付金额" v-model.number="serviceOrder.claimPayment.amount"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      type="number"
                                      hint="赔付上限: 首年80%, 第2年60%, 第3年40%"
                                      :persistent-hint="true"
                                      prepend-icon="mdi-currency-cny"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="新品订单号" rules="required" v-slot="{ errors }">
                        <v-text-field label="新品订单号" v-model="serviceOrder.case.新品订单号"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      prepend-icon="mdi mdi-new-box"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="新品发货时间" rules="required" v-slot="{ errors,validate }">
                        <datepickerField label="新品发货时间" :error="errors" @blur="validate()" name="新品发货时间"
                                         :readonly="readonly"
                                         outlined
                                         dense
                                         prependicon="mdi mdi-truck-cargo-container"
                                         v-model="serviceOrder.case.新品发货时间"></datepickerField>
                      </validation-provider>
                      <validation-provider name="拒保原因" rules="required" v-slot="{ errors }">
                        <v-text-field label="拒保原因" v-model="serviceOrder.case.拒保原因"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      prepend-icon="mdi-alert-octagon-outline"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                      <validation-provider name="采购订单发票类型" rules="required" v-slot="{ errors }">
                        <v-select v-model="serviceOrder.claimPayment.po.invoiceType" required
                                  prepend-icon="mdi-credit-card-outline"
                                  :readonly="readonly"
                                  outlined
                                  dense
                                  :items="['专票','电子专票','普票','电子普票','其他']" label="采购订单发票类型"
                                  :error-messages="errors"
                        ></v-select>
                      </validation-provider>
                      <validation-provider name="报销单号" rules="required" v-slot="{ errors }">
                        <v-text-field label="报销单号" v-model.number="serviceOrder.claimPayment.po.报销单号"
                                      :readonly="readonly"
                                      outlined
                                      dense
                                      type="number"
                                      prepend-icon="mdi-currency-cny"
                                      :error-messages="errors" required></v-text-field>
                      </validation-provider>
                    </v-card-text>
                  </v-card>
                  <v-card-actions>
                    <v-btn v-show="!readonly" color="success" @click="save()" :disabled="invalid">提交</v-btn>
                    <v-btn v-show="!readonly" class="ml-2 white--text" color="info" @click="save()">保存</v-btn>
                  </v-card-actions>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="6">
                  <div v-for="(group,groupName) in groupedImages" :key="groupName">
                    <div class="subtitle-2 mb-2">{{ groupName }}</div>
                    <v-row class="mb-2">
                      <v-col cols="4"
                             v-for="(img, index) in group"
                             :key="index">
                        <imageCard :height='120'
                                   v-model="serviceOrder.case.images[img.index]"
                                   :readonly="readonly"
                                   :label="img.name"
                                   :type="img.type||'image'"
                                   :url="img.url"></imageCard>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </validation-observer>

    </template>
  </v-dialog>
</template>

<script>
import cloudbase from "@/plugins/cloudbase";
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {required, regex, min, max} from 'vee-validate/dist/rules'
import imageCard from "@/components/image-card";
import tire from "@/views/service/jd-car/jd-car";
import datepickerField from "@/components/datepicker-field";
import codeFactory, {bizPrefix} from "@/utils/codeFactory";
import deepMerge from "@/utils/deepMerge";


extend('required', {
  ...required,
  message: '请输入{_field_}',
})

extend('passwordRegex', {
  ...regex,
  message: '请确保8-16位密码,必须包含数字及大小写字母',
})

extend('max', {
  ...max,
  message: '{_field_} 长度不可大于 {length}位',
})
extend('min', {
  ...min,
  message: '{_field_} 长度不可小于 {length}位',
})

export default {
  name: "addTireOrder",
  inheritAttrs: false,
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    },

  },
  components: {
    ValidationProvider,
    ValidationObserver,
    imageCard,
    datepickerField
  },
  data() {
    return {
      overlay: false,
      tire,
      menu: false,
      dialog: false,
      show: false,
      result: '',
      serviceOrder: this.setDefault(),
    }
  },
  created() {
  },
  methods: {
    setDefault() {
      return {
        code: '',
        created: new Date().getTime(),
        updated: new Date().getTime(),
        createBy: '',
        updateBy: '',
        type: '轮胎险',
        status: 1,
        comments: [],
        customer: {
          name: '',
          phone: '',
          address: ''
        },
        mainOrder: {
          code: '',
          sku: '',
          finishedDate: ''
        },
        secondaryOrder: {
          code: '',
          sku: ''
        },
        trouble: {
          category: '',
          desc: ''
        },
        claimPayment: {
          amount: '',
          method: '',
          po: {
            invoiceType: '',
            '报销单号': ''

          },
        },
        case: {
          images: [
            {
              name: '行驶本A面',
              required: false,
              group: '行驶证',
            },
            {
              name: '行驶本B面2',
              required: false,
              group: '行驶证'
            },
            {
              name: '行驶本C面',
              required: false,
              group: '行驶证'
            },
            {
              name: '轮胎规格',
              required: false,
              group: '故障轮胎'
            },
            {
              name: '轮胎DOT',
              required: false,
              group: '故障轮胎'
            },
            {
              name: '外部破损点',
              required: false,
              group: '故障轮胎'
            },
            {
              name: '内部破损点',
              required: false,
              group: '故障轮胎'
            },
            {
              name: '轮胎全貌',
              required: false,
              group: '故障轮胎'
            },
            {
              name: '视频',
              required: false,
              type: 'video',
              group: '故障轮胎'
            },
            {
              name: '故障侧45°',
              required: false,
              group: '车辆'
            },
            {
              name: '订单主商品信息',
              required: false,
              group: '订单'
            },
            {
              name: '订单交易信息',
              required: false,
              group: '订单'
            },
            {
              name: '轮胎安装信息',
              required: false,
              group: '订单'
            },
            {
              name: 'JD实名',
              required: false,
              group: '实名资料'
            },
            {
              name: '身份证A',
              required: false,
              group: '实名资料'
            },
            {
              name: '身份证B',
              required: false,
              group: '实名资料'
            },
            {
              name: '新品订单',
              required: false,
              group: '采购信息'
            },
            {
              name: '关系证明',
              required: false,
              group: '实名资料'
            },
          ]

        }

      }
    },
    init() {
      if (this.edit || this.readonly) {
        this.getOrders(this.order._id)
      } else {
        this.serviceOrder = this.setDefault()
        this.getNextCode()
      }
    },
    getOrders(id) {
      this.overlay = true
      cloudbase.database().collection('serviceOrders')
          .doc(id)
          .get()
          .then(s => {
            let [order] = s.data
            deepMerge(this.serviceOrder, order)
            this.fullFillImage()
            delete this.serviceOrder._id
            delete this.serviceOrder._openid
            this.overlay = false

          })
    },
    fullFillImage() {
      const imageNames = this.serviceOrder.case.images.map(s => {
        return s.name
      })
      const unExistsImages = this.serviceOrder.case.images.filter(s => {
        return !imageNames.includes(s.name)
      })
      console.log(unExistsImages)
      this.serviceOrder.case.images.push(...unExistsImages)

    },
    getNextCode() {
      this.overlay = true
      const serviceOrderCodeFactory = codeFactory(bizPrefix.轮胎险服务单)
      serviceOrderCodeFactory.getCode().then(code => {
        console.log(code)
        this.serviceOrder.code = code
      }).then(() => {
        this.overlay = false
      })
    },
    save() {
      this.overlay = true
      this.uploadImage()
          .then(() => {
            this.setChangeLog()
            if (this.edit) {
              return this.updateOrder()
            }
            return this.createOrder()
          })
          .then(() => {
            this.overlay = false
          })
          .catch(error => {
            console.error(error)
          })
    },
    setChangeLog() {
      const {updates = []} = this.serviceOrder
      console.log(this.$store.state.user)
      const {avatarUrl,nickName,uid} = this.$store.state.user
      const log = {
        updateBy: {avatarUrl,nickName,uid},
        updateTime: new Date().getTime(),
        before: {

        },
        after: {

        }
      }
      updates.push(log)
      this.serviceOrder.updates = updates
    },
    createOrder() {
      return cloudbase.database()
          .collection('serviceOrders')
          .add({
            ...this.serviceOrder,
          })
          .then(res => {
            console.log(res)
            this.close()
          })
    },
    updateOrder() {
      this.serviceOrder.updated = new Date().getTime()
      return cloudbase.database().collection('serviceOrders')
          .doc(this.order._id)
          .update({
            ...this.serviceOrder
          })
          .then(res => {
            console.log(res)
            this.close()
          })

    },
    uploadImage() {

      let promiseList = []
      this.serviceOrder.case.images
          .filter(s => s.file?.size)
          .forEach(s => {
            let promise = cloudbase.uploadFile({
              cloudPath: `serviceOrders/${this.serviceOrder.code}/${s.name}.${s.file.name.split('.')[1]}`,
              filePath: s.file,
            }).then(r => {
              console.log(r)
              s.fileId = r.fileID
              s.url = r.download_url
            })
            promiseList.push(promise)
          })
      return Promise.all(promiseList)

    },
    close() {
      this.$emit('close')
      this.dialog = false
    }
  },
  computed: {
    groupedImages() {
      let index = 0
      return this.serviceOrder.case.images.reduce((pre, cur) => {
        cur.index = index
        let group = pre[cur.group] || []
        group.push(cur)
        pre[cur.group] = group
        index++
        return pre
      }, {})
    },
    title() {
      return (this.edit || this.readonly) ? this.serviceOrder.code : '新建工单'
    },
    status() {
      return this.$store.state.projectSettings
          .find(s => {
            return s.name === 'JD轮胎险'
          }).status
          .map(s => {
            return {
              value: s.code,
              text: s.name
            }
          })
    }
  },
  watch: {
    'dialog'(v) {
      if (v) {
        this.init()
      }
    }
  }

}
</script>

<style scoped>
</style>
<style lang="sass">
$card-subtitle-padding: 8px
</style>