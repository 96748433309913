<template>
  <v-menu
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
  >
    <template v-slot:activator="{attrs }">
      <v-combobox
          v-model="fieldDate"
          chips
          small-chips
          :label="label"
          :prepend-icon="prependicon"
          readonly
          :outlined="outlined"
          :dense="dense"
          v-bind="attrs"
          @click.native="open"
          :error-messages="error"
          @blur="readonly?'': $emit('blur')"
      ></v-combobox>
    </template>
    <v-date-picker
        v-model="pickerDate"
        no-title
        scrollable
        @dblclick:date="save"
    >
      <v-spacer></v-spacer>
      <v-btn
          text
          color="primary"
          @click="cancel"
      >
        取消
      </v-btn>
      <v-btn
          text
          color="primary"
          :disabled="!pickerDate"
          @click="save"
      >
        确定
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "datepicker-field",
  model: {
    prop: 'fieldDate',
    event: 'selected'
  },
  props: {
    fieldDate: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    'error': {
      type: Array,
      default: () => {
        return []
      }
    },
    'outlined': {
      type: Boolean,
      default: false
    },
    'dense': {
      type: Boolean,
      default: false
    },
    'prependicon': {
      type: String,
      default: 'mdi-calendar'
    },
    'readonly': {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu: false,
      pickerDate: '',
    }
  },
  methods: {
    open() {
      if (!this.readonly) {
        this.pickerDate = this.fieldDate
        this.menu = true
      }
    },
    cancel() {
      this.menu = false
      this.pickerDate = this.fieldDate
      this.$emit('selected', this.pickerDate)
    },
    save() {
      this.$emit('selected', this.pickerDate)
      this.menu = false
    }
  },
  watch: {
    menu(v) {
      console.log('watch:menu:', v)
      if (!v) {
        this.$emit('selected', this.fieldDate)
      }
    }
  }
}
</script>

<style scoped>

</style>