const problem = [
    '爆胎', '鼓包'
]

const status = [
    '新建',
    '取消',
    '待补充资料',
    '审核中',
    '采购',
    '拒保',
    '完成',
]

const brands = [
    {
        'brand_id': 'brand-12836',
        'name': '米其林（MICHELIN）',
        'img': 'img30.360buyimg.com/popshop/jfs/t1/28272/10/6110/13707/5c46cc3cEdf2ae1dc/75552b56bebba001.jpg'
    }, {
        'brand_id': 'brand-6046',
        'name': '邓禄普（DUNLOP）',
        'img': 'img20.360buyimg.com/popshop/jfs/t1/6336/20/6658/5526/5be2d6deEf5a17a04/56e432aa99c56098.jpg'
    }, {
        'brand_id': 'brand-25508',
        'name': '佳通轮胎（Giti）',
        'img': 'img30.360buyimg.com/popshop/jfs/t1957/361/2806339188/6384/172c6f99/57173892Ndaf23d3c.jpg'
    }, {
        'brand_id': 'brand-7946',
        'name': '韩泰（Hankook）',
        'img': 'img20.360buyimg.com/popshop/jfs/t2761/147/506262781/3409/b393ef6d/57173644Nad77625f.jpg'
    }, {
        'brand_id': 'brand-25468',
        'name': '玛吉斯',
        'img': 'img30.360buyimg.com/popshop/jfs/t2335/327/2813213508/7222/c0a7b1a1/571737bdNfcd7507d.jpg'
    }, {
        'brand_id': 'brand-220056',
        'name': '马牌（Continental）',
        'img': 'img20.360buyimg.com/popshop/jfs/t2695/39/500306331/5890/8c6b8109/5717367fNa30a6e1d.jpg'
    }, {
        'brand_id': 'brand-16116',
        'name': '双星（DOUBLE STAR）',
        'img': 'img30.360buyimg.com/popshop/jfs/t2356/156/1857925700/5924/4e16fa39/56727b7dN5388308c.jpg'
    }, {
        'brand_id': 'brand-282269',
        'name': '朝阳',
        'img': 'img20.360buyimg.com/popshop/jfs/t1/115791/24/1257/4456/5e96ce03E780146d4/d1d5b28879a3f033.png'
    }, {
        'brand_id': 'brand-53499',
        'name': '锦湖',
        'img': 'img30.360buyimg.com/popshop/jfs/t1/8686/26/7124/3409/5be2d72dE789edcb3/d0afd09940c99d74.png'
    }, {
        'brand_id': 'brand-45539',
        'name': 'NEXEN',
        'img': 'img20.360buyimg.com/popshop/jfs/t1/10918/13/3192/2149/5be2d407E1baa4301/bef16746d69d024a.png'
    }, {
        'brand_id': 'brand-15080',
        'name': '三角（Triangle）',
        'img': 'img30.360buyimg.com/popshop/jfs/t2251/175/2765827575/7895/679c45a5/571736f6N14395da9.jpg'
    }, {
        'brand_id': 'brand-4719',
        'name': '倍耐力（Pirelli）',
        'img': 'img20.360buyimg.com/popshop/jfs/t1/6832/30/7436/4682/5be2d663E4a99bfc1/3f9c2d8e8b4c3fd5.jpg'
    }, {
        'brand_id': 'brand-7575',
        'name': '固特异（Goodyear）',
        'img': 'img30.360buyimg.com/popshop/jfs/t1/17492/20/14003/27456/5ca30d1bE5d16666b/3ed628ab9440a2ec.jpg'
    }, {
        'brand_id': 'brand-24742',
        'name': '优科豪马（yokohama）',
        'img': 'img20.360buyimg.com/popshop/g10/M00/16/0F/rBEQWFFvgUgIAAAAAAAhsG7kxs4AAEZHgO04U4AACHI756.jpg'
    }, {
        'brand_id': 'brand-12836',
        'name': '米其林（MICHELIN）',
        'img': 'img30.360buyimg.com/popshop/jfs/t1/28272/10/6110/13707/5c46cc3cEdf2ae1dc/75552b56bebba001.jpg'
    }, {
        'brand_id': 'brand-6046',
        'name': '邓禄普（DUNLOP）',
        'img': 'img20.360buyimg.com/popshop/jfs/t1/6336/20/6658/5526/5be2d6deEf5a17a04/56e432aa99c56098.jpg'
    }, {
        'brand_id': 'brand-25508',
        'name': '佳通轮胎（Giti）',
        'img': 'img30.360buyimg.com/popshop/jfs/t1957/361/2806339188/6384/172c6f99/57173892Ndaf23d3c.jpg'
    }, {
        'brand_id': 'brand-7946',
        'name': '韩泰（Hankook）',
        'img': 'img20.360buyimg.com/popshop/jfs/t2761/147/506262781/3409/b393ef6d/57173644Nad77625f.jpg'
    }, {
        'brand_id': 'brand-25468',
        'name': '玛吉斯',
        'img': 'img30.360buyimg.com/popshop/jfs/t2335/327/2813213508/7222/c0a7b1a1/571737bdNfcd7507d.jpg'
    }, {
        'brand_id': 'brand-220056',
        'name': '马牌（Continental）',
        'img': 'img20.360buyimg.com/popshop/jfs/t2695/39/500306331/5890/8c6b8109/5717367fNa30a6e1d.jpg'
    }, {
        'brand_id': 'brand-16116',
        'name': '双星（DOUBLE STAR）',
        'img': 'img30.360buyimg.com/popshop/jfs/t2356/156/1857925700/5924/4e16fa39/56727b7dN5388308c.jpg'
    }, {
        'brand_id': 'brand-282269',
        'name': '朝阳',
        'img': 'img20.360buyimg.com/popshop/jfs/t1/115791/24/1257/4456/5e96ce03E780146d4/d1d5b28879a3f033.png'
    }, {
        'brand_id': 'brand-53499',
        'name': '锦湖',
        'img': 'img30.360buyimg.com/popshop/jfs/t1/8686/26/7124/3409/5be2d72dE789edcb3/d0afd09940c99d74.png'
    }, {
        'brand_id': 'brand-45539',
        'name': 'NEXEN',
        'img': 'img20.360buyimg.com/popshop/jfs/t1/10918/13/3192/2149/5be2d407E1baa4301/bef16746d69d024a.png'
    }, {
        'brand_id': 'brand-15080',
        'name': '三角（Triangle）',
        'img': 'img30.360buyimg.com/popshop/jfs/t2251/175/2765827575/7895/679c45a5/571736f6N14395da9.jpg'
    }, {
        'brand_id': 'brand-4719',
        'name': '倍耐力（Pirelli）',
        'img': 'img20.360buyimg.com/popshop/jfs/t1/6832/30/7436/4682/5be2d663E4a99bfc1/3f9c2d8e8b4c3fd5.jpg'
    }, {
        'brand_id': 'brand-7575',
        'name': '固特异（Goodyear）',
        'img': 'img30.360buyimg.com/popshop/jfs/t1/17492/20/14003/27456/5ca30d1bE5d16666b/3ed628ab9440a2ec.jpg'
    }, {
        'brand_id': 'brand-24742',
        'name': '优科豪马（yokohama）',
        'img': 'img20.360buyimg.com/popshop/g10/M00/16/0F/rBEQWFFvgUgIAAAAAAAhsG7kxs4AAEZHgO04U4AACHI756.jpg'
    }, {'brand_id': 'brand-12836', 'name': '米其林（MICHELIN）'}, {
        'brand_id': 'brand-6046',
        'name': '邓禄普（DUNLOP）'
    }, {'brand_id': 'brand-25508', 'name': '佳通轮胎（Giti）'}, {
        'brand_id': 'brand-7946',
        'name': '韩泰（Hankook）'
    }, {'brand_id': 'brand-25468', 'name': '玛吉斯'}, {
        'brand_id': 'brand-220056',
        'name': '马牌（Continental）'
    }, {'brand_id': 'brand-16116', 'name': '双星（DOUBLE STAR）'}, {
        'brand_id': 'brand-282269',
        'name': '朝阳'
    }, {'brand_id': 'brand-53499', 'name': '锦湖'}, {'brand_id': 'brand-45539', 'name': 'NEXEN'}, {
        'brand_id': 'brand-15080',
        'name': '三角（Triangle）'
    }, {'brand_id': 'brand-4719', 'name': '倍耐力（Pirelli）'}, {
        'brand_id': 'brand-7575',
        'name': '固特异（Goodyear）'
    }, {'brand_id': 'brand-24742', 'name': '优科豪马（yokohama）'}, {
        'brand_id': 'brand-516277',
        'name': '莱威特'
    }, {'brand_id': 'brand-14071', 'name': '普利司通（Bridgestone）'}, {
        'brand_id': 'brand-25402',
        'name': '固铂（Cooper）'
    }, {'brand_id': 'brand-370662', 'name': '万力轮胎（WANLI TIRE）'}, {
        'brand_id': 'brand-104393',
        'name': '正新'
    }, {'brand_id': 'brand-219963', 'name': 'LANVIGATOR'}, {
        'brand_id': 'brand-140136',
        'name': '南港（NANKANG）'
    }, {'brand_id': 'brand-319249', 'name': '锦湖轮胎（KUMHO）'}, {
        'brand_id': 'brand-528980',
        'name': '岭头（LINGTOU）'
    }, {'brand_id': 'brand-174428', 'name': 'Haida'}, {
        'brand_id': 'brand-418099',
        'name': '迈瑞途'
    }, {'brand_id': 'brand-51286', 'name': '宝马（BMW）'}, {
        'brand_id': 'brand-403070',
        'name': '百路驰（BFGoodrich）'
    }, {'brand_id': 'brand-418098', 'name': 'APLUS'}, {
        'brand_id': 'brand-79550',
        'name': '前进（ADVANCE QJ）'
    }, {'brand_id': 'brand-355932', 'name': '朝阳轮胎（CHAOYANG）'}, {
        'brand_id': 'brand-556542',
        'name': '工马'
    }, {'brand_id': 'brand-224108', 'name': '万力轮胎（WANLITIRE）'}, {
        'brand_id': 'brand-564838',
        'name': '槿致'
    }, {'brand_id': 'brand-806099', 'name': '浦林'}, {'brand_id': 'brand-742775', 'name': '森麒麟'}, {
        'brand_id': 'brand-552548',
        'name': '回力（Warrior）'
    }, {'brand_id': 'brand-519414', 'name': '东风（DF）'}, {
        'brand_id': 'brand-8111',
        'name': '好运'
    }, {'brand_id': 'brand-135018', 'name': '千里马（CELIMO）'}, {
        'brand_id': 'brand-177996',
        'name': '京安途（Jauto）'
    }, {'brand_id': 'brand-546854', 'name': '双钱（DOUBLE COIN）'}, {
        'brand_id': 'brand-192953',
        'name': 'GOODRIDE'
    }, {'brand_id': 'brand-187417', 'name': '建大'}, {
        'brand_id': 'brand-603168',
        'name': '醉米（ZUIMI）'
    }, {'brand_id': 'brand-111467', 'name': '东风风神'}, {'brand_id': 'brand-55552', 'name': '途虎'}, {
        'brand_id': 'brand-534851',
        'name': '艾力特'
    }, {'brand_id': 'brand-644990', 'name': '兆通达'}, {'brand_id': 'brand-240763', 'name': '牧山'}, {
        'brand_id': 'brand-650595',
        'name': 'AOSEN'
    }, {'brand_id': 'brand-579192', 'name': '鹿凌青'}, {
        'brand_id': 'brand-467593',
        'name': '米士龙（MISHILONG）'
    }, {'brand_id': 'brand-180264', 'name': 'CHAOYANG'}, {
        'brand_id': 'brand-158122',
        'name': '卡贝罗（KABEILUO）'
    }, {'brand_id': 'brand-801426', 'name': '弋歌'}, {
        'brand_id': 'brand-703811',
        'name': 'YUSTARacing'
    }, {'brand_id': 'brand-660105', 'name': '宝柏（BAOBO）'}, {
        'brand_id': 'brand-750759',
        'name': '方探'
    }, {'brand_id': 'brand-546111', 'name': '享惠油'}, {
        'brand_id': 'brand-114167',
        'name': '全诺（TRAZANO）'
    }, {'brand_id': 'brand-107067', 'name': '赛轮'}, {'brand_id': 'brand-743552', 'name': '路航'}, {
        'brand_id': 'brand-706186',
        'name': '崇匠（ChongJiang）'
    }, {'brand_id': 'brand-664492', 'name': '及安盾消防'}, {
        'brand_id': 'brand-633199',
        'name': '跃通达'
    }, {'brand_id': 'brand-59123', 'name': '风神（AEOLUS）'}, {
        'brand_id': 'brand-594838',
        'name': 'DIANZHIZHU'
    }, {'brand_id': 'brand-756283', 'name': '西湖'}, {'brand_id': 'brand-605494', 'name': '派驰'}, {
        'brand_id': 'brand-210683',
        'name': '凌艺'
    }, {'brand_id': 'brand-202304', 'name': '韩泰轮胎'}, {'brand_id': 'brand-748725', 'name': 'MAKE MY DAY'}
]
const tire = {
    problem,
    brands,
    status,
    brandSelectionItems:brands.map(s=>{
        return s.name
    })
}

export {problem}
export default tire