<template>
  <resizeable-page>
    <template #header>
      <v-card class="elevation-2 mb-4">
        <v-card-text>
          <div class="button-container">
            <v-text-field
                v-model.trim="search"
                dense
                append-icon="mdi-filter-variant-plus"
                label="输入"
                outlined
                hide-details
                @click:append="getServiceOrder(1)"
                @keydown.enter="getServiceOrder(1)"
                @keydown.e.esc="clear()"
            >
              <template #label>
                <v-icon style="vertical-align: middle">
                  mdi-magnify
                </v-icon>
                输入<strong>工单号、主副订单、手机号、客户姓名</strong>按回车查询(Esc清除)
              </template>
              <template #append>
                <v-icon v-if="$store.getters.pagePermission.export" @click="exportOrders">mdi mdi-export-variant</v-icon>
                <car-service-order-filter v-model="filter" @submit="getServiceOrder(1)"></car-service-order-filter>
              </template>
            </v-text-field>
            <addTireOrder v-if="$store.getters.pagePermission.edit" color="primary" @close="getServiceOrder(1)">
              <v-icon>mdi mdi-plus</v-icon>
              新建
            </addTireOrder>
          </div>
        </v-card-text>
      </v-card>
    </template>
    <template #filter></template>
    <template #table="tableProps">
      <v-data-table
          class="elevation-2"
          :headers="headers"
          :items="serviceOrders"
          calculate-widths
          :items-per-page="limit"
          :page="page"
          item-key="_id"
          :search="search"
          dense
          fixed-header
          :height="tableProps.tableHeight"
          :server-items-length="orderCount"
          @update:page="getServiceOrder"
          :footer-props="footerOption"
      >
        <template #item.商品ID="{item}">
          <jd-product :sku-id="item.商品ID"></jd-product>
        </template>
        <template #item.status="{item}">
          <serviceOrderStatus :code="item.status" :project="project.name"></serviceOrderStatus>
        </template>
        <template #item.created="{item}">
          <div>{{ item.created | timestampToYYYYMMDD }}</div>
        </template>
        <template #item.updated="{item}">
          <div>{{ item.updated | timestampToYYYYMMDDH24 }}</div>
        </template>
        <template #item.creator="{item}">
<!--          <userAvatar update :updates="item.updates"></userAvatar>-->
          <changeLogAvatars :updates="item.updates"></changeLogAvatars>
        </template>
        <template #item.updaters="{item}">
          <changeLogAvatars update :updates="item.updates"></changeLogAvatars>
        </template>
        <template #item.action="{item}">
          <div class="button-container">
            <v-badge color="pink" dot overlap :value="dot(item)">
              <comment :id="item._id"></comment>
            </v-badge>
            <addTireOrder icon small
                          color="primary"
                          :readonly="true"
                          :order="item">
              <v-icon small dark>
                mdi-eye-arrow-left-outline
              </v-icon>
            </addTireOrder>
            <addTireOrder v-if="$store.getters.pagePermission.edit && getActions(item.status).editable"
                          icon small
                          :readonly="false"
                          color="primary"
                          :="false" :order="item" :edit="true"
                          @close="getServiceOrder(1)">
              <v-icon small dark>
                mdi-file-edit-outline
              </v-icon>
            </addTireOrder>
            <fee small
                 v-if="$store.getters.pagePermission.edit && getActions(item.status).fee"></fee>
            <deleteOrder v-if="$store.getters.pagePermission.edit && getActions(item.status).deletable"
                         @confirmDelete="removeOrder(item)"></deleteOrder>
          </div>
        </template>
      </v-data-table>
    </template>
  </resizeable-page>
</template>

<script>
import resizeablePage from "@/components/resizeable-page";
import db from "@/plugins/db";
import jdProduct from "@/views/contract/jdProduct";
import addTireOrder from './buttons/add'
import {timestampToYYYYMMDDH24, timestampToYYYYMMDD} from "@/utils/commonFileters";
import serviceOrderStatus from "@/views/service/component/serviceOrderStatus.vue";
import carServiceOrderFilter from "@/views/service/jd-car/buttons/carServiceOrderFilter";
import comment from "@/views/service/jd-car/buttons/comment";
import deleteOrder from "@/views/service/jd-car/buttons/deleteOrder";
import fee from "@/views/service/jd-car/buttons/fee";
import changeLogAvatars from "@/views/service/component/changeLogAvatars";

export default {
  components: {
    resizeablePage, jdProduct, addTireOrder, serviceOrderStatus, carServiceOrderFilter, comment, deleteOrder, fee,changeLogAvatars
  },
  props: {
    project: {
      type: Object
    }
  },
  data() {
    return {
      headers: [
        {
          value: "code",
          text: "工单号",
          sortable: false,
          align: "start",
          width: 70
        },
        {
          value: "status",
          text: "状态",
          sortable: false,
          align: "start",
          width: 50
        },
        {
          value: "trouble.category",
          text: "故障",
          sortable: false,
          align: "start",
          width: 100
        },
        {
          value: "customer.name",
          text: "客户姓名",
          sortable: false,
          align: "start",
          width: 80
        },
        {
          value: "customer.phone",
          text: "客户电话",
          sortable: false,
          align: "start",
          width: 80
        },
        {
          value: "mainOrder.code",
          text: "主品订单号",
          sortable: false,
          align: "start",
          width: 100
        },
        {
          value: "secondaryOrder.code",
          text: "服务订单号",
          sortable: false,
          align: "start",
          width: 100
        },
        {
          text: "创建人",
          value: "creator",
          sortable: false,
          align: "start",
          width: 70
        },
        {
          text: "更新人",
          value: "updaters",
          sortable: false,
          align: "start",
          width: 100
        },
        {
          value: "created",
          text: "创建时间",
          sortable: false,
          align: "start",
          width: 110
        },
        {
          value: "updated",
          text: "修改时间",
          sortable: false,
          align: "start",
          width: 120
        },
        {
          value: "action",
          text: "操作",
          sortable: false,
          align: "start",
          width: 150
        },
      ],
      serviceOrders: [],
      orderCount: 0,
      search: '',
      limit: 20,
      page: 1,
      filter: {},
      footerOption: {
        'items-per-page-options': [5, 10, 15, 20, -1]
      }
    }
  },
  created() {
    this.getServiceOrder(1)
  },
  methods: {
    getActions(status) {
      return this.$store.getters.getServiceSettings(this.project.name)
          .status.find(s => {
            return s.code === status
          })?.action || {}
    },
    clear() {
      this.search = ''
      this.getServiceOrder(1)
    },
    exportOrders() {
      db.collection('serviceOrders')
          .limit(1000)
          .get()
          .then(({data}) => {
            this.convertToCsv(data)
          })
    },
    convertToCsv(data) {
      console.log(data)
      const headers = ['id', 'code', 'lastUpdate', 'created', 'status', 'amount']
      let textValue = headers.join(",") + "\r\n";
      data.forEach(order => {
        let status = this.$store.state.projectSettings
            .find(project => {
              return project.name === this.project.name
            }).status
            .find(s => s.code === order.status)

        const values = [order._id, order.code, timestampToYYYYMMDDH24(order.updated), timestampToYYYYMMDDH24(order.created), status?.name, order.claimPayment?.amount]
        let row = values.join(",");
        textValue += row + "\r\n";
      });

      const blob = new Blob(["\ufeff" + textValue], {type: 'text/csv;charset=utf-8;'});
      // let encodedUri = encodeURI(blob);
      const url = URL.createObjectURL(blob)
      window.open(url);
    },
    getServiceOrder(pageNumber) {
      this.page = pageNumber
      const _ = db.command;
      let query = db.collection('serviceOrders')
      let params = {}
      if (this.search) {
        params = _.or(
            {
              'code': this.search
            },
            {
              'customer.phone': this.search
            },
            {
              'mainOrder.code': this.search
            },
            {
              'secondaryOrder.code': this.search
            },
            {
              'customer.name': this.search
            },
            {
              'case.新品订单号': this.search
            }
        )

      }
      console.log(params, this.filter)

      let filter = {...this.filter}
      if (filter.status) {
        if (filter.status.length === 0) {
          delete filter.status
        } else {
          filter.status = _.in(filter.status)
        }
      }
      query = query.where({
        params,
        ...filter
      })

      query.count()
          .then((res) => {
            this.orderCount = res.total
          });
      query.skip((pageNumber - 1) * this.limit)
          .limit(this.limit)
          .orderBy('created', 'desc')
          .get()
          .then((res) => {
            this.serviceOrders = res.data
          });
    }
    ,
    removeOrder(order) {
      db.collection('serviceOrders')
          .doc(order._id)
          .remove()
          .then(() => {
            this.getServiceOrder(1)
          })
    },
    dot(order) {
      const comments = order.comments || []
      const lastCommentTime = Math.max(...comments.map(s => {
        return s.commentTime
      })) || 0
      const updated = order.updated || 0
      return lastCommentTime > updated
    }
  },
  filters: {
    timestampToYYYYMMDDH24,
    timestampToYYYYMMDD
  }
}
</script>

<style scoped>

</style>