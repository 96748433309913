<template>
  <v-dialog
      v-model="dialog"
      scrollable
      max-width="800px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
          icon
          small
          elevation="4"
      >
        <v-icon small>
         mdi-comment-alert-outline
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar elevation="0">
        <v-toolbar-title>备注</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="height: 600px;">
        <v-timeline>
          <v-timeline-item v-for="comment in sortedComments" :key="comment.time">
            <template #icon>
              <v-avatar class="mt-2">
                <v-img
                    :src="comment.user.avatarUrl || 'https://avataaars.io/?avatarStyle=Circle&topType=LongHairFrida&accessoriesType=Kurt&hairColor=Red&facialHairType=BeardLight&facialHairColor=BrownDark&clotheType=GraphicShirt&clotheColor=Gray01&graphicType=Skull&eyeType=Wink&eyebrowType=RaisedExcitedNatural&mouthType=Disbelief&skinColor=Brown'"
                ></v-img>
              </v-avatar>
            </template>
            <template v-slot:opposite>
              <div>
                <strong>{{ comment.user.nickName }}</strong>
              </div>
              <div class="mt-1">
                <strong>{{ comment.commentTime | timestampToYYYYMMDDH24 }}</strong>
              </div>
            </template>
            <v-card class="elevation-2">

              <v-card-text>
                {{ comment.text }}
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-textarea
            label="工单备注  (shift+回车)可提交"
            v-model.trim="text"
            rows="3"
            outlined
            no-resize
            append-icon="mdi mdi-send"
            @click:append="addComment()"
            @keydown.shift.enter="addComment()"
        >
        </v-textarea>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import {timestampToYYYYMMDDH24} from "@/utils/commonFileters";
import db from "@/plugins/db";

const _ = db.command;

export default {
  name: "comment",
  props: ['id'],
  data() {
    return {
      dialog: false,
      comments: [],
      text: ''
    }
  },
  methods: {
    addComment() {
      if (this.text) {

        const {avatarUrl, nickName, uid} = this.$store.state.user
        const user = {avatarUrl, nickName, uid}
        const commentTime = new Date().getTime()
        const comment = {user, commentTime, text: this.text}
        db.collection("serviceOrders")
            .doc(this.id)
            .update({
              comments: _.push(comment)
            })
            .then(res => {
              console.log(res)
              this.comments.push(comment)
              this.text = ''
            });
      }
      this.text = ''
    },
    getComments() {
      db.collection('serviceOrders')
          .doc(this.id)
          .get().then(s => {
        const [order] = s.data
        this.comments = order?.comments || []
      })
    },

  },
  filters: {
    timestampToYYYYMMDDH24
  },
  computed: {
    sortedComments() {
      let comments = this.comments
      return comments.sort((a, b) => {
        return a.commentTime > b.commentTime
      })
    }
  },
  watch: {
    'dialog'(v) {
      if (v) {
        this.getComments()
      }
    }
  }
}
</script>

<style scoped>

</style>