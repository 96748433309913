<template>
  <div class="avatar-container">
    <div v-for="(item,index) in avatars" :key="index" class="item" :style="style(index)">
      <v-tooltip v-if="item.updateBy" bottom>
        <template v-slot:activator="{on, attrs }">
          <v-avatar size="32" class="ml-2 elevation-1 avatar"
                    v-bind="attrs"
                    v-on="on"
                    color="indigo"
          >
            <img v-if="!item.error" :src="item.updateBy.avatarUrl" @error="setError(index,item)">
            <span v-else class="overline white--text"> {{ item|textFormat }}</span>
          </v-avatar>
        </template>
        <span>{{ item.updateBy.nickName }}</span>
      </v-tooltip>
    </div>
  </div>
</template>

<script>

export default {
  name: "user-avatar",
  props: {
    updates: {
      type: Array,
      default: () => {
        return []
      }
    },
    update: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      avatars: []
    }
  },
  created() {
    this.avatars.push(...this.convertAvatars())
  },
  methods: {
    style(index) {
      return {
        left: (index) * 12 + 'px'
      }
    },
    setError(index,value){
      value.error = true
      this.$set(this.avatars,index,value)
    },
    convertAvatars() {
      const list = []
      if (this.update) {
        list.push(...this.updates.slice(-5))
      } else {
        const [first] = this.updates
        if (first) {
          list.push(first)
        }
      }
      return list.map(s => {
        s.error = false
        return s
      })

    }
  },
  filters: {
    textFormat(item) {
      const name = item.updateBy?.nickName || ''
      return name.substring(name.length -2)
    },
  }
}
</script>

<style scoped>
.avatar-container {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100%;
}

.item {
  position: absolute;
  top: 50%;
  transform: translate(-10px, -50%);
}

.avatar {
  border: solid 1px rgba(255, 255, 255, 0.5);
}
</style>