<template>
  <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
  >
    <template v-slot:activator="{on}">
      <v-btn
          color="primary"
          dark
          v-on="on"
          icon
          elevation="4"
          v-bind="$attrs"
      >
        <v-icon samll>
          mdi mdi-currency-usd
        </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">费用明细</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <h3 class="text-h7 mb-4">主品订单</h3>
          <v-row dense>
            <v-col cols="6">
              <v-text-field label="实付金额" type="number" min="1" v-model.number="number" outlined dense
                            required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="商品数量" outlined dense required></v-text-field>
            </v-col>
          </v-row>
          <h3 class="text-h7 mb-4">服务工单</h3>
          <v-row dense>
            <v-col cols="6">
              <v-select label="赔付方式" required
                        outlined
                        dense
                        :items="['京东E卡','现金','换新采购']"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field label="商品数量" outlined dense required></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="实付金额" outlined dense required></v-text-field>
            </v-col>
          </v-row>

        </v-container>
        <small>*indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
        >
          Close
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "fee",
  inheritAttrs: false,
  data() {
    return {
      dialog: false,
      number: 0
    }
  },
  methods: {
  }
}
</script>

<style scoped>

</style>