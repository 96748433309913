<template>
  <v-card @dragover="dragover" @dragleave="dragleave" @drop="drop" class="image-container">
    <v-alert
        v-model="alert"
        class="alert"
        type="warning"
        dark
        dismissible
        dense
    >仅可上传{{type==='image'?'图片':'视频'}}

    </v-alert>
    <v-img
        v-if="src"
        :src="src"
        :height="height"
        contain
        class="grey darken-4"
        @click="clickImage"
    >
      <v-fade-transition>
        <v-overlay
            v-if="hover"
            absolute
            color="#036358"
            z-index="-1"
        >
        </v-overlay>
      </v-fade-transition>
    </v-img>
    <v-img v-else
           :height="height"
           contain
           class="grey darken-4"
           @click="clickImage"
    >
      <template #placeholder>
        <v-row
            v-if="!hasValue && !readonly"
            class="fill-height ma-0"
            align="center"
            justify="center"
        >
          <v-icon large color="white">
            {{ type === 'image' ? ' mdi-camera-flip-outline' : 'mdi mdi-video-outline' }}
          </v-icon>
        </v-row>
      </template>

    </v-img>

    <v-card-title class="d-flex flex-row justify-space-between">
      <div class="subtitle-2"> {{ label }}</div>
      <v-icon
          v-show="src && !readonly" @click="selectFile"
      >
        mdi-camera-flip-outline
      </v-icon>
    </v-card-title>
    <v-file-input
        v-show="false"
        :accept="accept"
        ref="file"
        :value="image"
        @change="selected"
    ></v-file-input>
    <v-dialog scrollable v-model="showImage">
      <v-card height="90vh" width="90vw">
        <v-img v-show="type==='image'" height="100%" :src="src" contain></v-img>
        <video ref="video" v-show="type ==='video'" width="100%" height="100%" controls>
          <source :src="src" type="video/mp4">
          <source :src="src" type="video/ogg">
          <source :src="src" type="video/webm">
          您的浏览器不支持 video 标签。
        </video>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
export default {
  name: "image-card",
  model: {
    prop: 'value',
    event: 'changed'
  },
  props: {
    height: {
      type: Number,
      default: 120
    },
    url: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'label'
    },
    type: {
      type: String,
      default: 'image'
    },
    value: {},
    readonly: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      image: null,
      hover: false,
      alert: false,
      showImage: false
    }
  },
  methods: {
    selectFile() {
      this.$refs.file.$refs.input.click()
    },
    selected(value) {
      if (value) {
        this.setImage(value)
      }
    },
    setImage(image) {
      this.alert = false
      this.image = image
      let copy = {
        ...this.value,
        file: image,
        updated: true
      }
      this.$emit('changed', copy)
    },
    clickImage() {
      if (this.hasValue) {
        this.showImage = true
      } else {
        if (!this.readonly) {
          this.selectFile()
        }
      }
    },
    dragover(event) {
      event.preventDefault();
      if (!this.hover) {
        this.hover = true
      }
    },
    dragleave() {
      this.hover = false
    },
    drop(event) {
      event.preventDefault();
      this.hover = false
      let files = event.dataTransfer.files
      let image = Array.from(files).filter(s => {
        return s.type.includes(this.accept.split('/')[0], 0)
      })[0];
      if (image) {
        this.setImage(image)
      } else {
        this.alert = true
      }
    }
  },
  computed: {
    src() {
      if (this.value?.file?.size) {
        return URL.createObjectURL(this.value.file)
      }
      return this.url
    },
    hasValue() {
      return this.src.length > 0
    },
    accept() {
      let result = ''
      switch (this.type) {
        case 'video':
          result = 'video/mp4,video/x-m4v,video/*'
          break
        case 'image':
          result = 'image/*'
          break
      }
      return result
    }
  },
  watch: {
    showImage(v) {
      if (this.type === 'video') {
        if (this.$refs.video) {
          v ? this.$refs.video.play() : this.$refs.video.pause()
        }
      }
    }
  }
}
</script>

<style scoped>

.image-container {
  /*position: relative;*/
}

.alert {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;

}
</style>