var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"elevation":"4"}},'v-btn',_vm.$attrs,false),on),[_vm._t("default")],2)]}},{key:"default",fn:function(){return [_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1),_c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.title))])],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-card',{staticClass:"mb-6",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" 工单信息 ")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"状态","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"required":"","prepend-icon":"mdi-progress-check","readonly":_vm.readonly,"outlined":"","dense":"","elevation":"3","items":_vm.status,"label":"状态","error-messages":errors},model:{value:(_vm.serviceOrder.status),callback:function ($$v) {_vm.$set(_vm.serviceOrder, "status", $$v)},expression:"serviceOrder.status"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"客户姓名","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"客户姓名","prepend-icon":"mdi-account-star-outline","readonly":_vm.readonly,"outlined":"","dense":"","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.customer.name),callback:function ($$v) {_vm.$set(_vm.serviceOrder.customer, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"serviceOrder.customer.name"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"联系电话","rules":"required||min:11|max:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"联系电话","error-messages":errors,"readonly":_vm.readonly,"outlined":"","dense":"","counter":"","type":"number","prepend-icon":"mdi mdi-cellphone","required":""},model:{value:(_vm.serviceOrder.customer.phone),callback:function ($$v) {_vm.$set(_vm.serviceOrder.customer, "phone", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"serviceOrder.customer.phone"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"地址","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"地址","readonly":_vm.readonly,"outlined":"","dense":"","no-resize":"","rows":"2","prepend-icon":"mdi-map-marker-outline","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.customer.address),callback:function ($$v) {_vm.$set(_vm.serviceOrder.customer, "address", $$v)},expression:"serviceOrder.customer.address"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"故障类型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"required":"","items":_vm.tire.problem,"label":"故障类型","readonly":_vm.readonly,"prepend-icon":"mdi-alert","outlined":"","dense":"","error-messages":errors},model:{value:(_vm.serviceOrder.trouble.category),callback:function ($$v) {_vm.$set(_vm.serviceOrder.trouble, "category", $$v)},expression:"serviceOrder.trouble.category"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"故障描述","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"故障描述","readonly":_vm.readonly,"outlined":"","dense":"","no-resize":"","rows":"2","prepend-icon":"mdi-alert","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.trouble.desc),callback:function ($$v) {_vm.$set(_vm.serviceOrder.trouble, "desc", $$v)},expression:"serviceOrder.trouble.desc"}})]}}],null,true)})],1)],1),_c('v-card',{staticClass:"mb-6",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" 订单信息 ")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"主品订单号","rules":"required||min:10|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"主品订单号","prepend-icon":"mdi mdi-file-outline","readonly":_vm.readonly,"outlined":"","dense":"","counter":"","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.mainOrder.code),callback:function ($$v) {_vm.$set(_vm.serviceOrder.mainOrder, "code", $$v)},expression:"serviceOrder.mainOrder.code"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"商品数量","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"商品数量","prepend-icon":"mdi mdi-numeric","readonly":_vm.readonly,"outlined":"","dense":"","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.mainOrder.qty),callback:function ($$v) {_vm.$set(_vm.serviceOrder.mainOrder, "qty", $$v)},expression:"serviceOrder.mainOrder.qty"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"主品订单金额","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"主品订单金额","prepend-icon":"mdi mdi-currency-cny","readonly":_vm.readonly,"outlined":"","dense":"","type":"number","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.mainOrder.amount),callback:function ($$v) {_vm.$set(_vm.serviceOrder.mainOrder, "amount", _vm._n($$v))},expression:"serviceOrder.mainOrder.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"服务订单号","rules":"required||min:10|max:16"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"服务订单号","readonly":_vm.readonly,"outlined":"","dense":"","counter":"","prepend-icon":"mdi-hammer-screwdriver","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.secondaryOrder.code),callback:function ($$v) {_vm.$set(_vm.serviceOrder.secondaryOrder, "code", $$v)},expression:"serviceOrder.secondaryOrder.code"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"主品收货/安装日期","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('datepickerField',{attrs:{"label":"主品收货/安装日期","error":errors,"name":"主品收货/安装日期","readonly":_vm.readonly,"outlined":"","dense":""},on:{"blur":function($event){return validate()}},model:{value:(_vm.serviceOrder.mainOrder.finishedDate),callback:function ($$v) {_vm.$set(_vm.serviceOrder.mainOrder, "finishedDate", $$v)},expression:"serviceOrder.mainOrder.finishedDate"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"轮胎品牌","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"required":"","items":_vm.tire.brandSelectionItems,"readonly":_vm.readonly,"outlined":"","dense":"","label":"轮胎品牌","prepend-icon":"mdi-map-marker-outline","error-messages":errors},model:{value:(_vm.serviceOrder.case.brand),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case, "brand", $$v)},expression:"serviceOrder.case.brand"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"DOT","rules":"required||min:4|max:4"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"DOT","readonly":_vm.readonly,"outlined":"","dense":"","counter":"","prepend-icon":"mdi mdi-numeric-4-box-multiple-outline","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.case.dot),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case, "dot", $$v)},expression:"serviceOrder.case.dot"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"轮胎花纹","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"轮胎花纹","readonly":_vm.readonly,"outlined":"","dense":"","prepend-icon":"mdi-tire","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.case.轮胎花纹),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case, "轮胎花纹", $$v)},expression:"serviceOrder.case.轮胎花纹"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"轮胎规格","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"轮胎规格","readonly":_vm.readonly,"outlined":"","dense":"","prepend-icon":"mdi mdi-perspective-more","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.case.轮胎规格),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case, "轮胎规格", $$v)},expression:"serviceOrder.case.轮胎规格"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"车牌号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"车牌号","readonly":_vm.readonly,"outlined":"","dense":"","counter":"","prepend-icon":"mdi-car","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.case.车牌号),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case, "车牌号", $$v)},expression:"serviceOrder.case.车牌号"}})]}}],null,true)})],1)],1),_c('v-card',{staticClass:"mb-6",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v("赔付")]),_c('v-card-text',[_c('validation-provider',{attrs:{"name":"赔付方式","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"required":"","prepend-icon":"mdi-credit-card-outline","readonly":_vm.readonly,"outlined":"","dense":"","items":['京东E卡','现金','换新采购'],"label":"赔付方式","error-messages":errors},model:{value:(_vm.serviceOrder.claimPayment.method),callback:function ($$v) {_vm.$set(_vm.serviceOrder.claimPayment, "method", $$v)},expression:"serviceOrder.claimPayment.method"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"赔付数量","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"赔付数量","readonly":_vm.readonly,"outlined":"","dense":"","type":"number","prepend-icon":"mdi mdi-numeric","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.claimPayment.qty),callback:function ($$v) {_vm.$set(_vm.serviceOrder.claimPayment, "qty", _vm._n($$v))},expression:"serviceOrder.claimPayment.qty"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"赔付金额","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"赔付金额","readonly":_vm.readonly,"outlined":"","dense":"","type":"number","hint":"赔付上限: 首年80%, 第2年60%, 第3年40%","persistent-hint":true,"prepend-icon":"mdi-currency-cny","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.claimPayment.amount),callback:function ($$v) {_vm.$set(_vm.serviceOrder.claimPayment, "amount", _vm._n($$v))},expression:"serviceOrder.claimPayment.amount"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"新品订单号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"新品订单号","readonly":_vm.readonly,"outlined":"","dense":"","prepend-icon":"mdi mdi-new-box","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.case.新品订单号),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case, "新品订单号", $$v)},expression:"serviceOrder.case.新品订单号"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"新品发货时间","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var validate = ref.validate;
return [_c('datepickerField',{attrs:{"label":"新品发货时间","error":errors,"name":"新品发货时间","readonly":_vm.readonly,"outlined":"","dense":"","prependicon":"mdi mdi-truck-cargo-container"},on:{"blur":function($event){return validate()}},model:{value:(_vm.serviceOrder.case.新品发货时间),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case, "新品发货时间", $$v)},expression:"serviceOrder.case.新品发货时间"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"拒保原因","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"拒保原因","readonly":_vm.readonly,"outlined":"","dense":"","prepend-icon":"mdi-alert-octagon-outline","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.case.拒保原因),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case, "拒保原因", $$v)},expression:"serviceOrder.case.拒保原因"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"采购订单发票类型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"required":"","prepend-icon":"mdi-credit-card-outline","readonly":_vm.readonly,"outlined":"","dense":"","items":['专票','电子专票','普票','电子普票','其他'],"label":"采购订单发票类型","error-messages":errors},model:{value:(_vm.serviceOrder.claimPayment.po.invoiceType),callback:function ($$v) {_vm.$set(_vm.serviceOrder.claimPayment.po, "invoiceType", $$v)},expression:"serviceOrder.claimPayment.po.invoiceType"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"报销单号","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"报销单号","readonly":_vm.readonly,"outlined":"","dense":"","type":"number","prepend-icon":"mdi-currency-cny","error-messages":errors,"required":""},model:{value:(_vm.serviceOrder.claimPayment.po.报销单号),callback:function ($$v) {_vm.$set(_vm.serviceOrder.claimPayment.po, "报销单号", _vm._n($$v))},expression:"serviceOrder.claimPayment.po.报销单号"}})]}}],null,true)})],1)],1),_c('v-card-actions',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly),expression:"!readonly"}],attrs:{"color":"success","disabled":invalid},on:{"click":function($event){return _vm.save()}}},[_vm._v("提交")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(!_vm.readonly),expression:"!readonly"}],staticClass:"ml-2 white--text",attrs:{"color":"info"},on:{"click":function($event){return _vm.save()}}},[_vm._v("保存")])],1)],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"6"}},_vm._l((_vm.groupedImages),function(group,groupName){return _c('div',{key:groupName},[_c('div',{staticClass:"subtitle-2 mb-2"},[_vm._v(_vm._s(groupName))]),_c('v-row',{staticClass:"mb-2"},_vm._l((group),function(img,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('imageCard',{attrs:{"height":120,"readonly":_vm.readonly,"label":img.name,"type":img.type||'image',"url":img.url},model:{value:(_vm.serviceOrder.case.images[img.index]),callback:function ($$v) {_vm.$set(_vm.serviceOrder.case.images, img.index, $$v)},expression:"serviceOrder.case.images[img.index]"}})],1)}),1)],1)}),0)],1)],1)],1)],1)]}}])})]},proxy:true}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})}
var staticRenderFns = []

export { render, staticRenderFns }