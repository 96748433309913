import cloudbase from "@/plugins/cloudbase";


const bizPrefix = {
    '轮胎险服务单': 'LT'
}

const getNextSerial = (prefix) => {
    return cloudbase.callFunction({
        name: 'orders',
        data: {
            type: 'codeFactory',
            prefix
        }
    })
}

const codeFactory = (prefix) => {
    return {
        getCode() {
            return getNextSerial(prefix).then(v => {
                console.log(v)
                return v.result
            })
        }
    }
}


export {bizPrefix}
export default codeFactory

